<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <div class="">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="product-detail">
                                <div class="row">
                                  <div class="col-md-12 col-2">
                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product1"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1551339443_QGB400.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product2"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1551339449_QGB400%EF%BC%882%EF%BC%89.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product3"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1551339454_QGB400%EF%BC%883%EF%BC%89.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      <div
                                        v-if="product4"
                                        class="tab-pane fade active show"
                                        id="product-1"
                                        role="tabpanel"
                                      >
                                        <div
                                          class="product-img"
                                          style="
                                            padding: 5px;
                                            width: 450px;
                                            height: 350px;
                                          "
                                        >
                                          <img
                                            style="
                                              max-width: 100%;
                                              max-height: 100%;
                                            "
                                            src="http://v4-upload.goalsites.com/141/image_1551339459_QGB400%EF%BC%884%EF%BC%89.jpg"
                                            alt="img-1"
                                            class="
                                              img-fluid
                                              mx-auto
                                              d-block
                                              tab-img
                                              rounded
                                            "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="wehed()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1551339443_QGB400.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="thnine()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1551339449_QGB400%EF%BC%882%EF%BC%89.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="tletha()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1551339454_QGB400%EF%BC%883%EF%BC%89.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col" style="padding: 1px">
                                    <div
                                      style=""
                                      class="nav flex-column nav-pills"
                                      id="v-pills-tab"
                                      role="tablist"
                                      aria-orientation="vertical"
                                    >
                                      <a
                                        style="
                                          max-width: 100%;
                                          max-height: 100%;
                                          border-style: solid;
                                          padding: 0px;
                                          border-width: 1px;
                                        "
                                        class="nav-link"
                                        @click="arb3a()"
                                        id="product-1-tab"
                                        data-bs-toggle="pill"
                                        role="tab"
                                        aria-selected="false"
                                      >
                                        <img
                                          style="
                                            max-width: 100%;
                                            max-height: 100%;
                                          "
                                          src="http://v4-upload.goalsites.com/141/image_1551339459_QGB400%EF%BC%884%EF%BC%89.jpg"
                                          alt="img-1"
                                          class="
                                            img-fluid
                                            mx-auto
                                            d-block
                                            rounded
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col"></div>
                                </div>
                              </div>
                              <!-- end product img -->
                            </div>
                          </div>
                          <!-- end row -->
                        </div>
                      </div>
                      <!-- end card -->
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="mt-1 col-11">
                    <div class="row">
                      <h1 class="font-size-48 mb-3">
                        <strong>
                         
Large Vision Visual Glue Potting Machine PGV-502
                        </strong>
                      </h1>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Brand :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >D&H</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Model :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >PGV-502</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Applications :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                        >
                          Led Display,Display Module,Electronic Products And Where Glue Potting Demanded Products</span
                        >
                      </h5>
                    </div>
                    <br />
                    <div class="row">
                      <h5 style="font-weight: 700; font-size: 18px">
                        Suitable Material :
                        <span
                          style="
                            color: black;
                            font-weight: 400;
                            font-size: 16px;
                          "
                          >One-component glue or already mixed glue ,Solder Paste, Epoxy, Silicone, PU, Black Glue, White Glue, etc</span
                        >
                      </h5>
                    </div>
                    <br />
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-6">
                        <!-- 16:9 aspect ratio -->
                        <div class="ratio ratio-21x9">
                          <iframe
                            style="width: 450px; height: 250px"
                            title="YouToube Video"
                            src="https://www.youtube.com/embed/uZpUH1FAhx0"
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <h1 style="color: black; font-weight: 700">Features</h1>
                        <br />
                        <div class="row" style="font-size: 14px">
                          1、Intelligent identification of multiple products
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          2、Automatically generate gluing path
                        </div>
                        <br />
                        <div class="row" style="font-size: 14px">
                          3、Double CCD view
                        </div>
                        <br />
                          <div class="row" style="font-size: 14px">
                          4、Double stations, automatic circulation and continuous gluing
                        </div>
                        <br />
                          <div class="row" style="font-size: 14px">
                          5、Modular design for easy maintenance
                        </div>
                        <br />
                      </div>
                      <!-- end col -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <br /><br />

            <br />
            <b-tabs pills card style="font-size: 18px">
              <b-tab active title="Technical Data">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <ul class="container">
                      <li>XYZ working area：550*500*100mm(double movetrack)</li>
                      <li>Speed：1000mm/s（Movement speed）</li>
                      <li>
                        CCD resolution: 0.05mm
                      </li>
                      <li>
                        Accuracy：±0.01mm±2%（potting） ±2%（mixing）
                      </li>
                      <li>In the distance 300mm±0.02mm（Repeatability）</li>
                      <li>Drive mode: TBI Grinding screw ,Silver KK module,Panasonic servo.</li>
                      <li>
                        Movement Track:Dot/Line/Arc/Circle any other shape
                      </li>
                      <li>Programming interface: Win 7</li>
                      <li>
                       Input pressure：0.5MPa-0.7MPa
                      </li>
                      <li>Targeting method: Double CCD visual</li>
                      <li>Visual system: software of visual fixing</li>
                      <li>
                        Glue out speed:1-12 g/s
                      </li>
                      <li>
                    Meter control system：PLC/Gear pump/Screw pump/Double cylinder plunger pump
                      </li>
                      <li>
                        Ratio: 100:100 to 100:10
                      </li>
                      <li>
                       Viscosity：50cps-30000cps（Glue can be heated to decrease viscosity）
                      </li>
                      <li>
                       Glue control system: AB glue suction valve to achieve independent operation of AB glue
                      </li>
                      <li>Mixed mode: dynamic and static optional, instant mixing, easy to clean</li>
                      <li>
                       Tank：stainless steel(40L)、(25L)、（10L）optional
                      </li>
                      <li>
                        Power supply: 220V/50Hz/30000W (Power consumption without heater)
                      </li>
                      <li>Optional function:  dispensing system</li>
                      <li>Weight: 1300KG</li>
                      <li>Dimension:1880*1750*2050mm</li>
                      <li>Operation Method：Manual/Automatic</li>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <b-tab title="The Working Principle">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    Liquid A & B are kept in two tanks separately. There are two
                    sets of metering pumps inside the machine, draining out
                    liquids individually from tank A & B. Liquid A & B mix with
                    each other in the mixer pipe and dispensed out according to
                    the programmable data.
                    <img
                      src="https://v4-upload.goalsites.com/141/editor_1529630830_%E5%9B%BE%E7%89%871.png"
                      alt="img-1"
                      class="img-fluid mx-auto d-block rounded"
                    />
                  </div>
                </div>
              </b-tab>
              
            </b-tabs>

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  data() {
    return {
      product1: true,
      product2: false,
      product3: false,
      product4: false,
    
    };
  },
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {
      (this.product1 = true),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = false)
       
    },
    thnine() {
      (this.product1 = false),
        (this.product2 = true),
        (this.product3 = false),
        (this.product4 = false)
   
    },
    tletha() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = true),
        (this.product4 = false)

    },
    arb3a() {
      (this.product1 = false),
        (this.product2 = false),
        (this.product3 = false),
        (this.product4 = true)
      
    },
   

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>